import {
	Box,
	ButtonProps,
	CircularProgress,
	ListItem,
	ListItemIcon,
	ListItemProps,
	ListItemText,
	ListItemTextProps,
	Paper,
	PaperProps,
	useTheme,
} from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';

export default function PaperButton( {
	onClick,
	children,
	disabled = false,
	selected,
	loading,
	fullWidth,
	startIcon,
	size,
	textAlignCenter,
	listItemProps,
	listItemTextProps,
	disableSelectedTitle,
	title,
	subtitle,
	...props
}: {
	onClick?: () => void,
	children?: ReactNode,
	selected?: boolean,
	disabled?: boolean,
	fullWidth?: boolean,
	loading?: boolean,
	startIcon?: ReactNode,
	textAlignCenter?: boolean,
	listItemProps?: ListItemProps,
	listItemTextProps?: ListItemTextProps,
	disableSelectedTitle?: boolean,
	title?: Element | string | ReactNode,
	subtitle?: Element | string | ReactNode,
	size?: 'small' | 'medium' | 'large'
} & PaperProps & ButtonProps ) {
	const theme = useTheme();
	const [ disableButton, setDisableButton ] = useState( disabled );
	
	useEffect( () => {
		if ( loading || disabled ) setDisableButton( true );
		else setDisableButton( false );
	}, [ loading, disabled ] );
	
	const isDark = theme.palette.mode === 'dark';
	
	return (
		<Paper
			{...props}
			sx={{
				'width'         : fullWidth ? '100%' : 'fit-content',
				'display'       : 'flex',
				'alignItems'    : 'center',
				'justifyContent': 'center',
				'p'             : 1,
				'opacity'       : disableButton ? 0.5 : 1,
				'border'        : 2,
				'borderRadius'  : 2,
				'bgcolor'       : selected ? isDark ? 'alpha.primary' : 'alpha.secondary' : 'transparent',
				'borderColor'   : selected ? 'primary.main' : 'divider',
				'&:hover'       : onClick && {
					opacity: disableButton ? 0.5 : 0.8,
					cursor : disableButton ? 'unset' : 'pointer',
				},
				'&:active': onClick && {
					opacity: disableButton ? 0.5 : 0.8,
				},
				'&:focus': {
					opacity: disableButton ? 0.5 : 0.8,
				},
				'fontSize': '14px !important',
				'color'   : !disableSelectedTitle && selected ? 'primary.main' : 'text.primary',
				'height'  : size === 'small' ? 32 : size === 'large' ? 48 : 'unset',
				...props.sx,
			}}
			onClick={() => {
				if ( disableButton ) return;
				onClick?.();
			}}>
			{loading ? (
				<Box sx={{ width: 'fit-content' }}>
					<CircularProgress size={14}/>
				</Box>
			) : (
				<ListItem
					disablePadding
					sx={{ textAlign: textAlignCenter && 'center' }}
					{...listItemProps}>
					{startIcon && (
						<ListItemIcon sx={{ minWidth: 30 }}>
							{startIcon}
						</ListItemIcon>
					)}
					<ListItemText
						primary={children || title}
						secondary={subtitle}
						{...listItemTextProps}
						primaryTypographyProps={{
							fontWeight: '500',
							...listItemTextProps?.primaryTypographyProps,
						}}
					/>
				</ListItem>
			)}
		</Paper>
	);
}

