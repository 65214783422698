import PaperButton from '@/components/paperButton';
import safeDateFns from '@/helpers/safeFormat';
import { useMenu } from '@/providers/menu';
import { Box, Button, Collapse, Stack } from '@mui/material';
import { StaticDatePicker, StaticDatePickerProps } from '@mui/x-date-pickers-pro';
import { useEffect, useMemo, useState } from 'react';

type CustomDateTimePickerProps = {
	dateTime: Date,
	setDateTime: ( dateTime: Date ) => void,
	setSelectedTime?: ( selectedTime: boolean ) => void,
	renderOnScreen?: boolean,
	dayMinTime?: Date,
	dayMaxTime?: Date,
	earliestAvailableTime?: Date,
	intervalMinutes?: number,
	disableTimeSlots?: ( timeSlot: Date ) => boolean
} & StaticDatePickerProps<Date>;

const generateTimeShortcuts = (
	minTime: Date,
	maxTime: Date,
	earliestAvailableTime: Date,
	intervalMinutes: number = 30,
	disableTimeSlots?: ( timeSlot: Date ) => boolean,
) => {
	let startTime = new Date( Math.max( minTime?.getTime(), earliestAvailableTime.getTime() ) );
	const shortcuts: { label: string, value: Date }[] = [];
	// Extend maxTime by one interval to include the last slot
	const endTime = new Date( maxTime?.getTime() + intervalMinutes * 60000 );
	
	while ( startTime <= endTime ) {
		shortcuts.push( {
			label: safeDateFns.format( startTime, 'hh:mm a' ),
			value: new Date( startTime ),
		} );
		startTime = new Date( startTime.getTime() + intervalMinutes * 60000 );
	}
	
	if ( disableTimeSlots ) {
		return shortcuts.filter( ( shortcut ) => !disableTimeSlots( shortcut.value ) );
	}
	return shortcuts;
};

const isSameTime = ( time1: Date, time2: Date ) =>
	time1?.getHours() === time2?.getHours() && time1?.getMinutes() === time2?.getMinutes();

function DatePickerComponent( {
	dateTime,
	setDateTime,
	renderOnScreen,
	dayMinTime,
	dayMaxTime,
	intervalMinutes,
	disableTimeSlots,
	earliestAvailableTime,
	...props
}: CustomDateTimePickerProps & { dayMinTime: Date, dayMaxTime: Date, earliestAvailableTime: Date } ) {
	const timeShortcuts = useMemo( () => generateTimeShortcuts(
		dayMinTime,
		dayMaxTime,
		earliestAvailableTime,
		intervalMinutes,
		disableTimeSlots,
	), [ dayMinTime, dayMaxTime, intervalMinutes, earliestAvailableTime ] );
	
	const [ selectedTime, setSelectedTime ] = useState( timeShortcuts[ 0 ]?.value );
	
	const finalDateTime = safeDateFns.setHours( safeDateFns.setMinutes( safeDateFns.setSeconds( dateTime, 0 ), selectedTime?.getMinutes() ), selectedTime?.getHours() );
	
	useEffect( () => {
		if ( timeShortcuts[ 0 ]?.value ) {
			setSelectedTime( timeShortcuts[ 0 ].value );
		}
	}, [ timeShortcuts[ 0 ]?.value?.toISOString().slice( 0, 10 ) ] );
	
	useEffect( () => {
		if ( !finalDateTime ) return;
		setDateTime( finalDateTime );
	}, [ finalDateTime?.toISOString() ] );
	
	const datePickerConfig: StaticDatePickerProps<Date> = {
		value    : dateTime,
		onChange : ( newDate: Date ) => setDateTime( newDate ),
		slotProps: {
			actionBar: { actions: [] },
			toolbar  : { hidden: true },
		},
		minDate: earliestAvailableTime,
		view   : 'day',
		views  : [ 'day' ],
		sx     : {
			// '.MuiDateCalendar-root': { m: 0 },
			// '.MuiPickersLayout-contentWrapper': { width: 'fit-content' },
			'bgcolor'                               : 'transparent',
			'p'                                     : 0,
			'.MuiMultiInputDateRangeField-separator': { display: 'none' },
			'.MuiTypography-overline'               : { display: 'none' },
		},
		...props,
	};
	
	return (
		<Stack spacing={1}>
			<Collapse in={Boolean( timeShortcuts.length )}>
				<Stack direction='row' spacing={1} sx={{ overflowX: 'scroll', overflowY: 'hidden', pb: 1 }}>
					{timeShortcuts.map( ( shortcut, index ) => {
						const selected = isSameTime( finalDateTime, shortcut.value );
						return (
							<PaperButton
								key={index}
								textAlignCenter
								selected={selected}
								sx={{
									minWidth: 100,
								}}
								onClick={() => setSelectedTime( shortcut.value )}>
								{shortcut.label}
							</PaperButton>
						);
					} )}
				</Stack>
			</Collapse>
			<StaticDatePicker {...datePickerConfig}/>
		</Stack>
	);
}

export default function StoreDateAndTimePicker( {
	dateTime,
	setDateTime,
	renderOnScreen,
	dayMinTime = new Date(),
	dayMaxTime = new Date(),
	intervalMinutes,
	earliestAvailableTime = new Date(),
	...props
}: CustomDateTimePickerProps ) {
	const { showMenu } = useMenu();
	
	const commonProps: CustomDateTimePickerProps = {
		dateTime,
		setDateTime,
		renderOnScreen,
		dayMinTime,
		dayMaxTime,
		earliestAvailableTime,
		intervalMinutes,
		...props,
	};
	const handleOpenDatePickerMenu = ( event: React.MouseEvent<HTMLButtonElement> ) => {
		showMenu( () =>
			<Box p={1}><DatePickerComponent {...commonProps}/></Box>, event.currentTarget, {
			sx: { '.MuiPaper-root': { overflowX: 'hidden' } },
		} );
	};
	
	if ( renderOnScreen ) return <DatePickerComponent {...commonProps}/>;
	
	return (
		<Button onClick={handleOpenDatePickerMenu}>
			{dateTime ? safeDateFns.format( dateTime, 'MM/dd/yyyy hh:mm a' ) : 'Select Date & Time'}
		</Button>
	);
}
