import { useModal } from '@/providers/modal';
import { useDeepMemo } from '@apollo/client/react/hooks/internal';
import {
	CancelRounded as CancelRoundedIcon,
	Delete as DeleteIcon,
	KeyboardArrowLeftRounded as KeyboardArrowLeftRoundedIcon,
	KeyboardArrowRightRounded as KeyboardArrowRightRoundedIcon,
	Upload as UploadIcon,
} from '@mui/icons-material';
import { Avatar, Box, Button, ButtonGroup, Collapse, IconButton, Stack, SxProps } from '@mui/material';
import { nanoid } from 'nanoid';
import React, { useRef, useState } from 'react';
import Slider from 'react-slick';
import Sortable from '../sortable';
import StyledImage from '../styledImage';
import MultiImagesCropper from './multiImagesCropper';

type Props = {
	images: Array<string | Blob> | null,
	setImages?: ( value: any[] ) => void,
	smallImages?: boolean,
	aspectRatio?: number,
	displayImageWidth?: number | string,
	displayImageHeight: number | string,
	smallImageWidth?: number | string,
	smallImageHeight?: number | string,
	largeImageProps?: any
};

export default function ImageCarousel( {
	smallImages = true,
	images = [],
	aspectRatio = 1,
	displayImageWidth = 'auto',
	displayImageHeight,
	smallImageWidth,
	smallImageHeight,
	setImages,
	largeImageProps,
}: Props ) {
	const imgs = images || [];
	const { showModal } = useModal();
	const [ currentSlide, setCurrentSlide ] = useState( 0 );
	const ref = useRef<Slider>( null );
	const latestSlideIdx = useRef( 0 );
	
	const hasMultipleImages = imgs.length > 1;
	
	const imageList = useDeepMemo( () => imgs.map( ( image ) => ( {
		id : nanoid(),
		url: typeof image === 'string' ? image : URL.createObjectURL( image ),
		image,
	} ) ), [ imgs ] );
	
	function smallImage( src: string ) {
		return (
			<StyledImage
				alt='slider-images'
				src={src}
				sx={{
					border      : 1,
					borderColor : 'divider',
					borderRadius: 2,
					objectFit   : 'cover',
					width       : typeof smallImageWidth === 'number' ? smallImageWidth * aspectRatio : smallImageWidth,
					height      : smallImageHeight,
				}}
			/>
		);
	}
	
	return (
		<Box>
			<Box position='relative'>
				<Slider
					ref={ref}
					arrows={false}
					speed={600}
					beforeChange={( _prevIdx, index ) => setCurrentSlide( index )}>
					{imageList.map( ( { id, url } ) => (
						<StyledImage
							key={id}
							alt='slider-images'
							src={url}
							{...largeImageProps}
							sx={{
								border        : 1,
								borderColor   : 'divider',
								borderRadius  : 2,
								objectFit     : 'cover',
								width         : displayImageWidth,
								height        : displayImageHeight,
								imageRendering: '-webkit-optimize-contrast !important',
								...( largeImageProps?.sx as SxProps ),
							}}
						/>
					) )}
				</Slider>
				{hasMultipleImages && (
					<React.Fragment>
						<IconButton
							sx={{
								position: 'absolute',
								right   : 5,
								top     : '40%',
								zIndex  : 1,
							}}
							onClick={( evt ) => {
								evt.stopPropagation();
								ref.current?.slickNext();
							}}>
							<KeyboardArrowRightRoundedIcon
								sx={{
									color       : 'black',
									bgcolor     : 'white',
									borderRadius: 3,
								}}
							/>
						</IconButton>
						<IconButton
							sx={{
								position: 'absolute',
								left    : 5,
								top     : '40%',
								zIndex  : 1,
							}}
							onClick={( evt ) => {
								evt.stopPropagation();
								ref.current?.slickPrev();
							}}>
							<KeyboardArrowLeftRoundedIcon
								sx={{
									color       : 'black',
									bgcolor     : 'white',
									borderRadius: 3,
								}}
							/>
						</IconButton>
					</React.Fragment>
				)}
				{setImages && (
					<ButtonGroup
						sx={{
							...imgs.length && {
								position: 'absolute',
								right   : 2,
								bottom  : -10,
							},
						}}>
						{imgs.length && currentSlide !== 0 && (
							<Button
								variant='contained'
								color='secondary'
								onClick={() => {
									setImages( [
										imgs[ currentSlide ],
										...imgs.filter( ( _, index ) => index !== currentSlide ),
									] );
									ref.current?.slickGoTo( 0 );
								}}>
								Set Default
							</Button>
						)}
						<Button
							variant='contained'
							color='primary'
							startIcon={<UploadIcon/>}
							onClick={() => {
								showModal( MultiImagesCropper, undefined, {
									initialFiles: imgs,
									onSave      : async ( blobs ) => {
										setImages( blobs );
										setTimeout( () => ref.current?.slickGoTo( latestSlideIdx.current ), 100 );
									},
									ratio: aspectRatio,
									style: {
										width : '100%',
										height: displayImageHeight,
									},
								} );
							}}>
							Upload new Image
						</Button>
						{imgs.length && (
							<Button
								variant='contained'
								color='error'
								startIcon={<DeleteIcon/>}
								onClick={() => {
									setImages( imgs.filter( ( _, index ) => index !== currentSlide ) );
								}}>
								Delete This Image
							</Button>
						)}
					</ButtonGroup>
				)}
			</Box>
			<Collapse unmountOnExit in={Boolean( hasMultipleImages && smallImages && setImages )}>
				<Sortable
					items={imageList}
					style={{ marginTop: 10 }}
					setItems={( items ) => {
						setImages?.( items.map( ( { image } ) => image ) );
					}}
					renderItem={( { item, handle, index } ) => (
						<IconButton
							{...handle}
							key={item.id}
							color='primary'
							sx={{
								':not(:hover)': { '.delete': { opacity: 0 } },
								'bgcolor'     : currentSlide === index ? 'alpha.primary' : 'transparent',
							}}
							onClick={() => {
								ref.current?.slickGoTo( imageList.map( ( { id } ) => id ).indexOf( item.id ) );
							}}>
							{smallImage( item.url )}
							<Avatar
								className='delete'
								sx={{
									position  : 'absolute',
									bgcolor   : 'background.paper',
									height    : 25,
									width     : 25,
									top       : -12,
									right     : -12,
									cursor    : 'pointer',
									transition: '.2s',
								}}
								onClick={( evt ) => {
									evt.stopPropagation();
									setImages?.( imgs.filter( ( img ) => img !== item.id ) );
								}}>
								<CancelRoundedIcon sx={{ color: 'text.primary', opacity: 0.8 }} fontSize='small'/>
							</Avatar>
						</IconButton>
					)}
				/>
			</Collapse>
			{hasMultipleImages && smallImages && !setImages && (
				<Stack direction='row' sx={{ flexWrap: 'wrap', mt: 2 }}>
					{imageList.map( ( { id, url }, index ) => (
						<IconButton
							key={id}
							color='primary'
							sx={{
								bgcolor: currentSlide === index ? 'alpha.primary' : 'transparent',
							}}
							onClick={() => ref.current?.slickGoTo( imageList.map( ( { id } ) => id ).indexOf( id ) )}>
							{smallImage( url )}
						</IconButton>
					) )}
				</Stack>
			)}
		</Box>
	);
}
