import { ResponsiveModalContainer } from '@/providers/modal/responsiveModal';
import { CancelRounded as CancelRoundedIcon } from '@mui/icons-material';
import { Avatar, Box, Typography } from '@mui/material';
import 'cropperjs/dist/cropper.min.css';
import imageCompression from 'browser-image-compression';
import { useSnackbar } from 'notistack';
import { useRef, useState } from 'react';
import type { ReactCropperProps } from 'react-cropper';
import { Cropper } from 'react-cropper';
import { useDropzone } from 'react-dropzone';
import { v4 } from 'uuid';
import Sortable from '../sortable';
import StyledImage from '../styledImage';

type Props = {
	initialFiles?: Array<Blob | string>,
	ratio?: number,
	circle?: boolean,
	maxMB?: number,
	onSave: ( blobs: Array<Blob | string> ) => Promise<void>
} & ReactCropperProps;

export default function MultiImagesCropper( {
	initialFiles,
	ratio = 1,
	circle,
	maxMB = 10,
	onSave,
	...props
}: Props ) {
	const { enqueueSnackbar } = useSnackbar();
	const [ files, setFiles ] = useState<Array<{
		file: Blob | string,
		src: string,
		id: string,
		cropped: boolean
	}>>( () => ( initialFiles || [] ).map( ( file ) => {
		if ( typeof file === 'string' ) return { file, src: file, id: v4(), cropped: true };
		return { file, src: URL.createObjectURL( file ), id: v4(), cropped: true };
	} ) );
	const [ cropper, setCropper ] = useState<Cropper>();
	const [ selected, setSelected ] = useState( files[ 0 ] );
	const isChanged = useRef<boolean>( false );
	
	const { getRootProps, getInputProps } = useDropzone( {
		multiple: true,
		accept  : { 'image/*': [ '.jpeg', '.png', '.jpg', '.gif', '.bmp' ] },
		onDrop  : async ( newFiles ) => {
			if ( newFiles.some( ( file ) => file.size > maxMB * 1024 * 1024 ) ) {
				enqueueSnackbar( `File size too large, max ${maxMB} MB` );
				return;
			}
			
			const compressed = await Promise.all( newFiles.map( ( file ) => file.size < 1.5 * 1000 * 1024
				? file
				: imageCompression( file, { maxSizeMB: 2, maxWidthOrHeight: 1000 } ) ) );
			
			const filesAppended = compressed.map( ( file ) => ( {
				file,
				src    : URL.createObjectURL( file ),
				id     : v4(),
				cropped: false,
			} ) );
			
			setFiles( ( prev ) => [ ...prev, ...filesAppended ] );
			setSelected( filesAppended[ 0 ] );
		},
	} );
	
	const handleCrop = async () => {
		if ( !cropper ) return;
		if ( !selected ) return;
		const blob = await new Promise<Blob>( ( resolve ) => {
			const canvas = cropper.getCroppedCanvas();
			canvas?.toBlob( ( b ) => b ? resolve( b ) : null );
		} );
		selected.file = blob;
		selected.src = URL.createObjectURL( blob );
		selected.cropped = true;
	};
	
	return (
		<ResponsiveModalContainer
			title='Select Image'
			onSave={async () => {
				if ( !selected.cropped ) await handleCrop();
				await onSave( files.map( ( { file } ) => file ) );
			}}>
			<Box
				{...getRootProps()}
				sx={{
					'p'           : 2,
					'textAlign'   : 'center',
					'borderColor' : 'divider',
					'border'      : '1px dashed',
					'borderRadius': 2,
					'transition'  : '.3s',
					'color'       : 'text.secondary',
					':hover'      : {
						color      : 'primary.main',
						bgcolor    : 'alpha.primary',
						borderColor: 'primary.main',
						cursor     : 'pointer',
					},
				}}>
				<input {...getInputProps()}/>
				<Typography sx={{ color: 'text.secondary' }}>
					Drag & drop files here, or click to select files
				</Typography>
			</Box>
			<Box
				hidden={!files[ 0 ]?.src}
				sx={circle ? {
					'.cropper-view-box': { borderRadius: '50%' },
					'.cropper-face'    : { bgcolor: 'inherit !important' },
				} : undefined}>
				<Cropper
					guides
					aspectRatio={ratio}
					src={selected?.src}
					viewMode={1}
					minCropBoxHeight={10}
					minCropBoxWidth={10}
					background={false}
					checkOrientation={false}
					zoomTo={1}
					cropend={() => isChanged.current = true}
					zoom={( evt ) => {
						if ( evt.detail.ratio !== 1 ) isChanged.current = true;
					}}
					onInitialized={( instance ) => {
						setCropper( instance );
					}}
					{...props}
				/>
			</Box>
			<Sortable
				items={files.map( ( file ) => ( { id: file.id, src: file.src } ) )}
				style={{ marginTop: 14 }}
				setItems={( items ) => {
					const newOrder = items.map( ( { id } ) => id );
					const newFiles = newOrder.map( ( id ) => files.find( ( file ) => file.id === id )! );
					setFiles( newFiles );
				}}
				renderItem={( { item, handle } ) => (
					<Box
						{...handle}
						display='inline-flex'
						position='relative'
						sx={{
							'width'       : 60 * ratio,
							'height'      : 60,
							'mr'          : 2,
							'cursor'      : 'grab',
							'border'      : item.id === selected?.id ? '1px solid' : 'none',
							':not(:hover)': { '.delete': { opacity: 0 } },
						}}
						onClick={async () => {
							if ( selected?.id === item.id ) return;
							if ( selected?.cropped === false || isChanged.current ) await handleCrop();
							setSelected( files.find( ( file ) => file.id === item.id )! );
							isChanged.current = false;
						}}>
						<StyledImage
							id={item.id}
							sx={{ width: '100%', height: '100%', objectFit: 'cover' }}
							src={item.src}
							alt='image'
						/>
						<Avatar
							className='delete'
							sx={{
								position  : 'absolute',
								bgcolor   : 'background.paper',
								height    : 25,
								width     : 25,
								top       : -12,
								right     : -12,
								cursor    : 'pointer',
								transition: '.2s',
							}}
							onClick={( evt ) => {
								evt.stopPropagation();
								const newFiles = files.filter( ( file ) => file.id !== item.id );
								if ( selected?.id === item.id ) {
									const selectedIdx = files.findIndex( ( file ) => file.id === item.id );
									setSelected( newFiles[ selectedIdx ] || null );
								}
								setFiles( newFiles );
							}}>
							<CancelRoundedIcon sx={{ color: 'text.primary', opacity: 0.8 }} fontSize='small'/>
						</Avatar>
					</Box>
				)}
			/>
		</ResponsiveModalContainer>
	);
}
