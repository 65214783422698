import AddressWithFlag from '@/components/addressWithFlag';
import CallEffect from '@/components/callEffect';
import Form from '@/components/form';
import AsyncLoadingButton from '@/components/form/asyncLoading/asyncLoadingButton';
import FormPhoneField from '@/components/form/fields/phoneField';
import FormTextField from '@/components/form/fields/textField';
import LargeChip from '@/components/largeChip';
import { ListItemComp } from '@/components/listItem';
import StoreDateAndTimePicker from '@/components/muiDatePickerFields/customSingleDatePicker';
import PaperButton from '@/components/paperButton';
import { useGraphqlResult } from '@/data/query/graphqlProvider';
import safeDateFns, { safeConvertToZonedTime, safeFormatInTimeZone } from '@/helpers/safeFormat';
import { useGetStoreAtom, useStorePublicRead, useUpdateOnlineStoreAtom } from '@/pages/p/store/context';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useModal } from '@/providers/modal';
import { Order, Store } from '@/types/schema';
import { getBrowserTimezone } from '@/utils/timezone';
import {
	BoltRounded as BoltRoundedIcon,
	CalendarMonthRounded as CalendarMonthRoundedIcon,
	LocalShippingRounded as LocalShippingRoundedIcon,
	LocationOnRounded as LocationOnRoundedIcon,
	StorefrontRounded as StorefrontRoundedIcon,
} from '@mui/icons-material';
import { Box, Collapse, Paper, Stack, type Theme, useMediaQuery } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { differenceInDays, format, isAfter, isBefore, subHours } from 'date-fns';
import { formatInTimeZone, utcToZonedTime } from 'date-fns-tz';
import { capitalize, pick, toLower } from 'lodash-es';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';
import AddressFormModal from '../addressModal';
import getStoreHoursAvailability, { getFromDate, getGlobalMinTime, getToDate } from '../utils/storeAvilibility';

const useStoreValidationSchema = ( store: Store ) => yup.object().shape( {
	firstName: yup
		.string()
		.required( 'First name is required' )
		.max( 250, 'Name is too long' ),
	lastName: yup
		.string()
		.required( 'Last name is required' )
		.max( 250, 'Name is too long' ),
	email: yup
		.string()
		.email( 'Please enter a valid email' )
		.required( 'Email is required' ),
	phone: yup
		.string(),
	serviceDate: yup
		.date()
		.nullable().optional()
		.required( 'Service date is required' )
		.test(
			'store-open-on-day',
			'The store is closed on the selected day',
			function( value ) {
				const timezone = getBrowserTimezone();
				const dateTz = utcToZonedTime( value || new Date(), timezone );
				const dayOfWeek = format( dateTz, 'eeee' ).toLowerCase();
				const dayWithFromAndTo = store.hours?.[ dayOfWeek ];
				
				// If there are no operating hours for the day, fail the test
				return !!dayWithFromAndTo;
			},
		)
		.test(
			'service-within-operating-hours',
			'Service time must be within store operating hours',
			function( value ) {
				if ( !value ) return true; // Skip test if value is not set
				
				const timezone = getBrowserTimezone();
				const dateTz = utcToZonedTime( value, timezone );
				const dayOfWeek = format( dateTz, 'eeee' ).toLowerCase();
				const dayWithFromAndTo = store.hours?.[ dayOfWeek ];
				
				if ( !dayWithFromAndTo ) return true; // Skip test if there are no operating hours defined for the day
				
				const from = subHours( getFromDate( dayWithFromAndTo?.from, dateTz ), 1 );
				const to = getToDate( dayWithFromAndTo?.to, dateTz );
				
				// If the selected date is not within operating hours, fail the test
				return !( isBefore( dateTz, from ) || isAfter( dateTz, to ) );
			},
		)
		.test(
			'service-date-before-today',
			'Cannot select a past day',
			function( value ) {
				if ( !value ) return true; // Skip test if value is not set
				
				const timezone = getBrowserTimezone();
				const nowInTz = utcToZonedTime( new Date(), timezone );
				const dateTz = utcToZonedTime( value, timezone );
				return differenceInDays( dateTz, nowInTz ) >= 0;
			},
		)
		.test(
			'service-time-not-in-past',
			'Cannot select a past time for today',
			function( value ) {
				if ( !value ) return true; // Skip test if value is not set
				
				const timezone = getBrowserTimezone();
				const nowInTz = utcToZonedTime( new Date(), timezone );
				const dateTz = utcToZonedTime( value, timezone );
				
				const isToday = format( nowInTz, 'yyyy-MM-dd' ) === format( dateTz, 'yyyy-MM-dd' );
				// If the selected date is today, ensure the selected time is not in the past
				return !( isToday && isBefore( dateTz, nowInTz ) );
			},
		),
} );

export function extractStoreOrderNote( note ) {
	if ( !note ) return '';
	const match = note.match( /\*\*\s*(.+)/ ); // matches ** and removes any whitespace after it, and returns rest of the note
	return match ? match[ 1 ].trim() : note.trim();
}

export default function ClientInfoForm( { goToNextStep }: { goToNextStep: () => void } ) {
	const { showModal } = useModal();
	const { user } = useUserInfo();
	const setOnlineStoreAtom = useUpdateOnlineStoreAtom();
	const { enqueueSnackbar } = useSnackbar();
	const { store } = useStorePublicRead();
	const queryClient = useQueryClient();
	const validationSchema = useStoreValidationSchema( store );
	const isMobile = useMediaQuery<Theme>( ( { breakpoints } ) => breakpoints.down( 'sm' ) );
	const timezone = getBrowserTimezone();
	const order = useGraphqlResult<Order>();
	
	const { atomCustomerAddress, atomStoreType, atomStoreLocation } = useGetStoreAtom();
	
	const orderPrices = store.prices.filter( ( price ) => price.metadata?.type === atomStoreType );
	const earliestAvailableTime = getGlobalMinTime( store, atomStoreType, atomStoreLocation );
	
	const fiferClient = store?.id === '703a31dc-a704-4f1f-89b5-762e3799f166';
	
	return (
		<Form
			noClose
			initialValues={{
				firstName         : user?.firstName || '',
				lastName          : user?.lastName || '',
				email             : user?.email || '',
				phone             : '',
				image             : user?.image || '',
				note              : extractStoreOrderNote( order.notes ) || '',
				serviceDate       : earliestAvailableTime,
				type              : atomStoreType || '',
				deliveryTimeOption: 'standard',
			}}
			validationSchema={validationSchema}
			onSubmit={async ( values ) => {
				try {
					if ( store.type.length > 1 && !values.serviceDate ) {
						enqueueSnackbar( 'Please provide a service date for your order', { variant: 'error' } );
						return;
					}
					await axios.post( '/api/orderPublicWrite', {
						store,
						orderPrice : orderPrices,
						lineItems  : order.lineItems,
						orderNumber: order.externalId || order.number,
						id         : order.id,
						zipCode    : atomCustomerAddress?.postalCode,
						metadata   : order.metadata,
						storeType  : atomStoreType,
					} );
					
					const note = `${values.serviceDate
						? `${formatInTimeZone( new Date( values.serviceDate ), timezone, 'PPp' )} **`
						: ''} ${values.note} `;
					
					await axios.post( '/api/createStoreClientAndAddToOrder', {
						...pick( values, [
							'email',
							'firstName',
							'lastName',
							'phone',
							'image',
							'location',
							'serviceDate',
						] ),
						note,
						title    : note,
						company  : store.company.id,
						orderId  : order.id,
						storeType: toLower( atomStoreType ),
						address  : atomCustomerAddress,
					} );
					
					await queryClient.invalidateQueries( [ 'order' ] );
					
					goToNextStep();
				} catch ( e ) {
					console.log( e );
				}
			}}>
			{( formik ) => {
				const {
					      selectedDateMinTime,
					      selectedDateMaxTime,
					      availabilityMessage,
				      } = getStoreHoursAvailability( store, formik.values.serviceDate, atomStoreType, atomStoreLocation );
				
				return (
					<Stack spacing={2}>
						<CallEffect
							func={() => {
								let minTime: Date | undefined;
								if ( fiferClient ) {
									minTime = new Date( '2025-02-08T10:00:00' );
								} else if ( !earliestAvailableTime ) {
									minTime = undefined;
								} else if ( !selectedDateMinTime ) {
									minTime = earliestAvailableTime;
								} else {
									minTime = new Date( Math.max( selectedDateMinTime.getTime(), earliestAvailableTime.getTime() ) );
								}
								formik.setFieldValue( 'serviceDate', minTime );
							}}
							deps={[]}
						/>
						<FormTextField
							fullWidth
							name='firstName'
							label='First Name'
						/>
						<FormTextField
							fullWidth
							name='lastName'
							label='Last Name'
						/>
						<FormTextField
							fullWidth
							name='email'
							label='Email'
							type='email'
							autoComplete='email'
						/>
						<FormPhoneField
							name='phone'
							label='Phone Number'
							onChange={( value, data, event, formattedValue ) => {
								formik.setFieldValue( 'phone', formattedValue );
							}}
						/>
						<Collapse in={store.type.length > 1}>
							<Stack spacing={1}>
								<ListItemComp
									listItemProps={{ disablePadding: true }}
									primary='Order Type'
									primaryTypographyProps={{ variant: 'h6' }}
									secondary='What type of order would you like to place?'
								/>
								<PaperButton
									fullWidth
									disableSelectedTitle
									title='Delivery'
									selected={atomStoreType === 'DELIVERY'}
									startIcon={<LocalShippingRoundedIcon/>}
									sx={{ bgcolor: 'background.default' }}
									onClick={() => setOnlineStoreAtom( { atomStoreType: 'DELIVERY' } )}
								/>
								<PaperButton
									fullWidth
									disableSelectedTitle
									title='Pick Up'
									selected={atomStoreType === 'PICKUP'}
									startIcon={<StorefrontRoundedIcon/>}
									sx={{ bgcolor: 'background.default' }}
									onClick={() => setOnlineStoreAtom( { atomStoreType: 'PICKUP' } )}
								/>
							</Stack>
						</Collapse>
						<Collapse in={Boolean( atomStoreType )}>
							<Stack spacing={1}>
								<ListItemComp
									listItemProps={{ disablePadding: true }}
									primary={`${capitalize( atomStoreType )} Address`}
									primaryTypographyProps={{ variant: 'h6' }}
									secondary={atomStoreType === 'PICKUP'
										? 'Confirm your pick up location'
										: `View or edit your ${toLower( atomStoreType )} address`}
								/>
								<PaperButton
									fullWidth
									startIcon={<LocationOnRoundedIcon/>}
									sx={{ bgcolor: 'background.default' }}
									title={<AddressWithFlag
										address={atomStoreType === 'PICKUP'
											? atomStoreLocation?.address
											: atomCustomerAddress}
									/> as any}
									subtitle={atomStoreType === 'DELIVERY' && ( atomCustomerAddress
										? 'Edit Address'
										: 'Choose Delivery Address' )}
									listItemTextProps={{ primaryTypographyProps: { variant: 'h6' } }}
									onClick={atomStoreType !== 'PICKUP' ? () => {
										showModal( AddressFormModal, { maxWidth: 'xs' }, { deliveryOnly: true } );
									} : undefined}
								/>
							</Stack>
						</Collapse>
						<Stack spacing={1}>
							<ListItemComp
								listItemProps={{ disablePadding: true }}
								primary={`${capitalize( atomStoreType )} Options`}
								secondary={availabilityMessage}
								primaryTypographyProps={{ variant: 'h6' }}
							/>
							{!fiferClient && (
								<PaperButton
									fullWidth
									disableSelectedTitle
									title='Standard'
									subtitle={`Order ready by ${safeFormatInTimeZone( earliestAvailableTime, 'PPp' )}`}
									selected={formik.values.deliveryTimeOption === 'standard'}
									startIcon={<BoltRoundedIcon/>}
									sx={{ bgcolor: 'background.default' }}
									listItemTextProps={{ primaryTypographyProps: { variant: 'h6' } }}
									onClick={() => {
										formik.setFieldValue( 'deliveryTimeOption', 'standard' );
										formik.setFieldValue( 'serviceDate', earliestAvailableTime );
									}}
								/>
							)}
							<PaperButton
								fullWidth
								disableSelectedTitle
								title='Schedule'
								subtitle='Choose a different date and time'
								selected={formik.values.deliveryTimeOption === 'schedule'}
								sx={{ bgcolor: 'background.default' }}
								startIcon={<CalendarMonthRoundedIcon/>}
								listItemTextProps={{ primaryTypographyProps: { variant: 'h6' } }}
								onClick={() => formik.setFieldValue( 'deliveryTimeOption', 'schedule' )}
							/>
							<Collapse unmountOnExit in={formik.values.deliveryTimeOption === 'schedule'}>
								<Paper
									sx={{
										bgcolor    : !isMobile ? 'background.default' : 'transparent',
										p          : !isMobile ? 1 : 0,
										border     : !isMobile ? 1 : 0,
										borderColor: 'divider',
										overflow   : 'hidden',
									}}>
									<StoreDateAndTimePicker
										renderOnScreen
										disableTimeSlots={( dateSlot ) => {
											const currentDateTime = safeConvertToZonedTime( new Date() );
											const isBeforeCurrentTime = safeDateFns.isBefore( dateSlot, currentDateTime );
											
											// Check if fiferClient is true and dateSlot is not February 8th, 2025
											const isNotFebruary8th = !safeDateFns.isSameDay( dateSlot, new Date( '2025-02-08T10:00:00' ) );
											
											console.log( isNotFebruary8th );
											if ( fiferClient && isNotFebruary8th ) {
												return true;
											}
											return isBeforeCurrentTime;
										}}
										dateTime={formik.values.serviceDate}
										intervalMinutes={15}
										setDateTime={( dateTime ) => formik.setFieldValue( 'serviceDate', dateTime )}
										dayMinTime={!fiferClient ? selectedDateMinTime : new Date( '2025-02-08T10:00:00' )}
										dayMaxTime={!fiferClient ? selectedDateMaxTime : new Date( '2025-02-08T13:45:00' )}
										earliestAvailableTime={!fiferClient
											? earliestAvailableTime
											: new Date( '2025-02-08T10:00:00' )}
										maxDate={fiferClient ? new Date( '2025-02-08T13:45:00' ) : undefined}
									/>
								</Paper>
							</Collapse>
							<Collapse in={Boolean( formik.errors && formik.errors.serviceDate )}>
								<LargeChip label={formik.errors.serviceDate} color='error' sx={{ my: 1 }}/>
							</Collapse>
							<FormTextField
								fullWidth
								multiline
								name='note'
								label='Order Note'
								placeholder='Order note'
								variant='outlined'
								inputProps={{ maxLength: 3000 }}
								rows={3}
							/>
						</Stack>
						<Box>
							<AsyncLoadingButton
								disabled={atomStoreType === 'DELIVERY' ? !atomCustomerAddress : false}
								variant='contained'
								color='primary'
								loading={formik.isSubmitting}
								onClick={formik.handleSubmit}>
								Continue
							</AsyncLoadingButton>
						</Box>
					</Stack>
				);
			}}
		</Form>
	);
}
